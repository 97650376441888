import React from "react";
import ReactDOM from "react-dom";
import LoginComponent from "./LoginComponent";


document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <LoginComponent mode="login" />,
    document.getElementById('login-user-root')
  );
});   